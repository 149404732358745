import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import queryString from "query-string"
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CDefinition,
  CNewsList,
  CBtnList,
  CMultiSelectBtn,
  CSingleSelect,
  CStayPlan,
  CFooterSearch
} from '../../../components/_index';
import stayPlanGet from "../../../utils/stayPlan-get"

// markup
const SubPage = ({ data, location }: { data: any, location: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { type, category } = queryString.parse(location.search)
  const initype = (type) ? (Array.isArray(type)) ? type : [type] : new Array()
  const inicategory = (category) ? (Array.isArray(category)) ? '' : category : ''

  const [checked, setChecked] = useState(initype);
  const [selected, setSelected] = useState(inicategory);
  const [moreflag, setMoreflag] = useState(true);
  const maxcount = 15
  const onMoreClick = () => {
    setMoreflag(false)
  }

  useEffect(() => {
    const url = new URL(window.location.href)
    const query = checked.map((item) => {
      return {
        name: 'type',
        value: item,
      }
    })
    if (selected) {
      query.push({
        name: 'category',
        value: selected,
      })
    }
    const replacequery = (query.length > 0) ? '?' + query.map((item) => `${item.name}=${item.value}`).join('&') : url.pathname
    history.replaceState({}, '', replacequery)
  })

  const filterDataAll: Array<any> = stayPlanGet().filter((item: any) => {
    let checkedflag = false
    let selectedflag = false
    if (checked.length < 1) {
      checkedflag = true
    } else {
      checkedflag = item.node.mealtype.some((el: string) => checked.includes(el))
    }
    if (!selected) {
      selectedflag = true
    } else {
      selectedflag = item.node.category.includes(selected)
    }
    return checkedflag && selectedflag
  })
  const filterData = filterDataAll.slice(0, moreflag ? maxcount : undefined)

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        type='ja'
        data={{
          title: {
            main: '宿泊プラン',
            sub: <>STAY PLAN</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/plan/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/plan/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className='l_sect u_pt100'>
        <LWrap>
          <CSectTitle
            exClass='u_tal u_mbSmall'
            title={{
              en: '',
              ja: 'プランの絞り込み',
            }}
          />
          <p className="u_fs16 u_fwb">食事タイプ</p>
          <CMultiSelectBtn
            checked={checked}
            setChecked={setChecked}
            selectList={[
              '朝食付',
              '夕食付',
              '夕・朝食付',
              '素泊まり',
            ]}
          />
          <p className="u_fs16 u_fwb u_mt40">カテゴリ</p>
          <div className="u_mt20 u_mbMedium">
            <CSingleSelect
              selected={selected}
              setSelected={setSelected}
              selectList={[
                '季節のおすすめ',
                'スイート・特別フロア',
                '記念日・プロポーズ',
                'ファミリー',
                '女子会・ご褒美',
                'お一人様',
                '早期割引・連泊割',
                '体験・エンターテイメント',
              ]}
            />
          </div>
          <CStayPlan data={filterData} />
          {(moreflag && filterDataAll.length > maxcount) &&
            <ul className="c_btnList c_btnList__center">
              <li className="item">
                <button type="button" className="c_btn c_btn__borderBlack" onClick={onMoreClick}>
                  <span className="c_btn_label">もっと見る</span>
                  <i className="icon-plus"></i>
                </button>
              </li>
            </ul>}
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CFooterSearch formId="footer" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
